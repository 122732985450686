<template lang="html">
  <section class="wrapper">
    <div class="welcome">
      <h1><img src="https://phiveleven.com/511.vector.png" alt="511 Logo" class="logo"></h1>
    </div>
    <div id="firebaseui-auth-container"></div>
  </section>
</template>

<script>
import firebase from 'firebase';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {config} from '../helpers/firebaseConfig';
export default {
  name: 'auth',
  mounted() {
    var uiConfig = {
      signInSuccessUrl: '/success',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]
      };
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
    },
}
</script>

<style lang="css">
  .welcome img{
      margin: 4rem auto;
      display: block;
      width: auto;
      max-width: 80vw;
    }
</style>