<template>
  <section class="container" ref="content">
    <div class="row gutters-sm mt-5">
      <div class="col-md-4 mb-3">
        <div class="card"  v-for="reportData in reportDataList" :key="reportData.id">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`https://bootdey.com/img/Content/avatar/${reportData.avatar}.png`" alt="Admin" class="rounded-circle" width="150" />
              <div class="mt-3">
                <h4>{{reportData.NombreQuienLlenaSolicitud}}</h4>
                <p class="text-secondary mb-1">{{reportData.MedioLlego}}</p>
                <p class="tags mr1 text-muted">Score: 
                  <span class="badge badge-success" v-if="reportData.VALORPLAN >= 80"> {{ reportData.VALORPLAN }}</span>
                  <span class="badge badge-warning" v-else-if="reportData.VALORPLAN < 80 && reportData.VALORPLAN > 71"> {{ reportData.VALORPLAN }}</span>
                  <span class="badge badge-danger" v-else> {{ reportData.VALORPLAN }}</span>
                </p>
                <button class="btn btn-outline-primary" @click='goBack'>Regresar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="row gutters-sm">
            <div class="col-sm-12 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">available</i>USER Data</h6>
                  <small>User Data</small>
                  <div class="mb-3">
                    <pre>{{reportDataList}}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8" v-for="reportData in reportDataList" :key="reportData.id">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Full Name</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{reportData.NombreQuienLlenaSolicitud}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{reportData.email}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">User id</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{reportData.id}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Mobile</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{reportData.mobile}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Address</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                Ubicación
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Fecha de reporte</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{reportData.FechaCreacionSolicitud}}
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row gutters-sm">
          <div class="col-sm-12 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3">Upload Document</h6>
                <small><i><b><sup>*</sup>Any kind of file</b></i></small>
                <div class="mb-3">
                  <input type="file" @change="searchFile()" id="files" name="files[]" multiple />
                  <button class="btn btn-info" @click.prevent='uploadFile()'>Upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters-sm">
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Uploaded Documents</h6>
                <small>Web Design</small>
                <button class="btn btn-info" @click.prevent='getAll()'>List Documents</button>
                <button class="btn btn-info" @click.prevent='doAjax()'>Do Ajax</button>
                <button class="btn btn-info" @click.prevent='storeData()'>Store Data</button>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="row gutters-sm">
          <div class="col-sm-12 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">available</i>Graphs</h6>
                <small>Dendrogram</small>
                <div class="mb-3">
                  <iframe width="100%" height="1199.015625" frameborder="0" src="https://observablehq.com/embed/c6d1d3d11aea73a9?cells=topicLavoroGraph"></iframe>
                </div>
                <small>Radar Chart</small>
                <div class="mb-3">
                  <iframe width="100%" height="697.015625" frameborder="0" src="https://observablehq.com/embed/c6d1d3d11aea73a9?cells=radarChartLavoro"></iframe>
                </div>
                <small>Gauge Chart</small>
                <div class="mb-3">
                  <iframe width="100%" height="586" frameborder="0" src="https://observablehq.com/embed/@shahmitansh/untitled?cells=gChart%2Cviewof+gaugeValue"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row gutters-sm">
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase'
import database from 'firebase/database'; 
import storage from 'firebase/storage';

export default {
 data(){
     return {
       photo: '',
       userId: '',
       name: '',
       email: '',
       user: {},
       usuario: [],
       emailVerified: 'Verifying',
       reportDataList: [],
     }
   },
   created() {
     var vm = this
     firebase.auth().onAuthStateChanged(function(user) {
       if (user) {
         vm.user = user;
         vm.name = vm.user.displayName;
         vm.email = vm.user.email;
         vm.photo = vm.user.photoURL;
         vm.userId = vm.user.uid;
         vm.usuario = user;
         vm.emailVerified = vm.user.emailVerified;
      }
      console.log('User', user)
    });
     //this.storeData()
  },
  async mounted() {
    //await this.storeData()
    await this.getReportData()
  },
  methods: {
    doAjax() {
               const loading = this.$loading.show({ 
        isFullPage: true
      })
            },
    async uploadFile() {
      //this.isLoading = true;
      const loading = this.$loading.show({ 
        isFullPage: true
      })
      try{  
        var storageRef = firebase.storage().ref();
        var docsRef = storageRef.child('docs');
        // Get the file from DOM
        var file = document.getElementById("files").files[0];
        console.log('the file is', file);
        //dynamically set reference to the file name
        var thisRef = storageRef.child('docs/'+this.user.uid+'/'+file.name);
        //put request upload file to firebase storage
        thisRef.put(file).then(function(snapshot) {
           alert("File successfully uploaded")
           console.log('Uploaded a blob or file!');
        });
        var date = new Date();
        var newPostKey = firebase.database().ref().child('users/'+this.user.uid).push().key;
        firebase.database().ref('users/'+this.user.uid+'/'+newPostKey).update({'document uploaded at:': date.toString(), 'file path': thisRef.toString(), 'file name': file.name, 'file type': file.type});
      }
      finally{
        loading.hide(); 
      }                    
    },
    logOut() {
      firebase.auth().signOut();
    },
    storeData(user) {
      var date = new Date();
      firebase.database().ref('users/'+this.user.uid).update({'id': this.user.uid, 'email': this.user.email, 'name': this.user.displayName, 'last login at:': date.toString(), 'profile picture': this.user.photoURL, 'email verified': this.user.emailVerified, 'phone number': this.user.phoneNumber,});
    },
    searchFile(){
      var file = document.getElementById("files").files[0];
      console.log('The selected file is: ',file);
    },
    listFiles(){
      var storageRef = firebase.storage().ref();
      var listRef = storageRef.child('/docs/'+this.user.uid);
      // Find all the prefixes and items.
      listRef.listAll().then(function(res) {
        res.prefixes.forEach(function(folderRef) {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          console.log('Docs are', listRef.storage)
        });
      }).catch(function(error) {
        // Uh-oh, an error occurred!
      });
    },
    async getAll() {
    var ref = firebase.database().ref('users/'+this.user.uid).orderByKey().equalTo(`${this.user.uid}`)
      ref.once('value', (snapshot) => {
        var childData2 = snapshot.child(this.user.uid).val();
        snapshot.forEach((childSnapshot) => {
          
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          
          this.applications = childData2;
          //var childDataIhm = childSnapshot.val();

          console.log('childKey', childKey);
          console.log('childData', childData);
          console.log('childData2', childData2);
          //console.log('applications nombre', this.applications['id_IHM_123_5586614218'])
          //console.log('applications', applications)
          //console.log('childData IHM', childDataIhm);

        });
      });
    },
    async getReportData() {
      fetch("reporte1.json")
        .then(response => response.json())
        .then(data => (this.reportDataList = data));
    },
    goBack(){
      this.$router.push('/success')
    }
  },
};
</script>
<style scoped>
  button{ 
    min-width: 150px;
    margin: 0.5rem 1rem; }
</style>