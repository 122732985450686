// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const config = {
  apiKey: "AIzaSyC4DwHg_4_64LhcxgnULF8YbaFn1YyE2rg",
  authDomain: "data-ae1df.firebaseapp.com",
  databaseURL: "https://data-ae1df.firebaseio.com",
  projectId: "data-ae1df",
  storageBucket: "data-ae1df.appspot.com",
  messagingSenderId: "832062507983",
  appId: "1:832062507983:web:2b0f7c7969b95be75160ba",
  measurementId: "G-H7RQ1J42KF"
};