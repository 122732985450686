import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import firebaseui from 'firebaseui';
import database from 'firebase/database'; 
import router from './router'
import {config} from './helpers/firebaseConfig'
import 'bootstrap';
import 'jquery'; 
import 'popper.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {
	loader: 'dots',
  color: '#007bff',
  lockScroll: true
});    
Vue.use(VueRouter)

new Vue({
  router,
  created() {
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        this.$router.push('/success')
      } else {
        this.$router.push('/auth')
      }
     });
    },
  el: '#app',
  render: h => h(App)
});