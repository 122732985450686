<template>
  <section class="container" ref="content">
    <div class="row justify-content-md-center">
      <div class="col-md-auto col-sm-12">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h3>Signup Succeeded.</h3><br>Welcome <strong v-if="name">{{name}}</strong><strong v-else>{{phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6')}}</strong><br><strong>Now you can perform some actions </strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row gutters-sm mt-5">
      <div class="col-md-4 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <h2>Te damos la bienvenida</h2>
              <img :src="photo" id="avatar" class="rounded-circle" onerror="this.style.display='none'" v-if="photo" />
              <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="150" v-else />
              <div class="mt-3">
                <h4>{{ name }}</h4>
                <p class="text-secondary mb-1">{{userId}}</p>
                <p class="tags mr1 text-muted">email verified: 
                  <span class="badge badge-success" v-if="emailVerified"> {{ emailVerified }}</span>
                  <span class="badge badge-secondary" v-else> {{ emailVerified }}</span>
                </p>
                <p class="text-muted tags" v-show="phoneNumber">phone number:
                  {{ phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6') }}
                </p>
                <p class="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                <button class="btn btn-outline-danger" @click='logOut'>Logout</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Website</h6>
              <span class="text-secondary">https://web.com</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Github</h6>
              <span class="text-secondary">gitUser</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
              <span class="text-secondary">@twitterUser</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram mr-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
              <span class="text-secondary">igUser</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
              <span class="text-secondary">fbUser</span>
            </li>
          </ul>
        </div>
        <!--<div class="mt-3">
          <div class="row gutters-sm">
            <div class="col-sm-12 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">available</i>USER Data</h6>
                  <small>User Data</small>
                  <div class="mb-3">
                    <pre>{{user}}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col-md-8">
        <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">available</i>Interviews</h6>
        <div class="card mb-3" v-for="reportData in reportDataList" :key="reportData.id">
          <div class="card-body">
            <div class="row justify-content-md-center">
              <img :src="`https://bootdey.com/img/Content/avatar/${reportData.avatar}.png`" alt="Admin" class="rounded-circle" width="150" />
            </div>
            <div class="row justify-content-md-center mt-2">
              <p class="tags mr1 text-muted">Score: 
                <span class="badge badge-success" v-if="reportData.VALORPLAN >= 80"> {{ reportData.VALORPLAN }}</span>
                <span class="badge badge-warning" v-else-if="reportData.VALORPLAN < 80 && reportData.VALORPLAN > 71"> {{ reportData.VALORPLAN }}</span>
                <span class="badge badge-danger" v-else> {{ reportData.VALORPLAN }}</span>
              </p>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Full Name</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <b>{{reportData.NombreQuienLlenaSolicitud}}</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Riesgo Delito</h6>
              </div>
              <div v-if="reportData.RiesgoDelito < 25" class="col-sm-9 text-success">
                <b>{{reportData.RiesgoDelito}} - Bajo</b>
              </div>
              <div v-else-if="reportData.RiesgoDelito > 25 && reportData.RiesgoDelito < 50" class="col-sm-9 text-warning">
                <b>{{reportData.RiesgoDelito}} - Medio</b>
              </div>
              <div v-else class="col-sm-9 text-danger">
                <b>{{reportData.RiesgoDelito}} - Alto</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <b>{{reportData.email}}</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">User id</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <b>{{reportData.id}}</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Mobile</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <b>{{reportData.mobile}}</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Medio</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <b>{{reportData.MedioLlego}}</b>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="mb-3 text-center col">
                <button class="btn btn-outline-primary" @click='goToGraphs'>View Details</button>
              </div>
            </div>
          </div>
        </div> 
        <!--<div class="row gutters-sm">
          <div class="col-sm-12 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3">Upload Document</h6>
                <small><i><b><sup>*</sup>Any kind of file</b></i></small>
                <div class="mb-3">
                  <input type="file" @change="searchFile()" id="files" name="files[]" multiple />
                  <button class="btn btn-info" @click.prevent='uploadFile()'>Upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters-sm">
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Uploaded Documents</h6>
                <small>Web Design</small>
                <button class="btn btn-info" @click.prevent='getAll()'>List Documents</button>
                <button class="btn btn-info" @click.prevent='doAjax()'>Do Ajax</button>
                <button class="btn btn-info" @click.prevent='storeData()'>Store Data</button>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <!--<div class="row gutters-sm">
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                <small>Web Design</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Website Markup</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>One Page</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Mobile Template</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small>Backend API</small>
                <div class="progress mb-3" style="height: 5px">
                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase'
import database from 'firebase/database'; 
import storage from 'firebase/storage';

export default {
 data(){
     return {
       photo: '',
       userId: '',
       name: '',
       email: '',
       phoneNumber: '',
       user: {},
       usuario: [],
       emailVerified: 'Verifying',
       reportDataList: [],
     }
   },
   created() {
     var vm = this
     firebase.auth().onAuthStateChanged(function(user) {
       if (user) {
         vm.user = user;
         vm.name = vm.user.displayName;
         vm.email = vm.user.email;
         vm.phoneNumber = vm.user.phoneNumber;
         vm.photo = vm.user.photoURL;
         vm.userId = vm.user.uid;
         vm.usuario = user;
         vm.emailVerified = vm.user.emailVerified;
      }
      console.log('User', user)
    });
     //this.storeData()
  },
  async mounted() {
    //await this.storeData()
    await this.getReportData()
    await this.dismissAlert()
  },
  methods: {
    async dismissAlert(){
      window.setTimeout(function() {
        document.querySelector("div.alert").fadeTo(500, 0).slideUp(500, function(){
          document.querySelector(this).remove(); 
        });
      }, 2000);
    },
    doAjax() {
      const loading = this.$loading.show({ 
        isFullPage: true
      })
            },
    async uploadFile() {
      //this.isLoading = true;
      const loading = this.$loading.show({ 
        isFullPage: true
      })
      try{  
        var storageRef = firebase.storage().ref();
        var docsRef = storageRef.child('docs');
        // Get the file from DOM
        var file = document.getElementById("files").files[0];
        console.log('the file is', file);
        //dynamically set reference to the file name
        var thisRef = storageRef.child('docs/'+this.user.uid+'/'+file.name);
        //put request upload file to firebase storage
        thisRef.put(file).then(function(snapshot) {
           alert("File successfully uploaded")
           console.log('Uploaded a blob or file!');
        });
        var date = new Date();
        var newPostKey = firebase.database().ref().child('users/'+this.user.uid).push().key;
        firebase.database().ref('users/'+this.user.uid+'/'+newPostKey).update({'document uploaded at:': date.toString(), 'file path': thisRef.toString(), 'file name': file.name, 'file type': file.type});
      }
      finally{
        loading.hide(); 
      }                    
    },
    logOut() {
      firebase.auth().signOut();
    },
    storeData(user) {
      var date = new Date();
      firebase.database().ref('users/'+this.user.uid).update({'id': this.user.uid, 'email': this.user.email, 'name': this.user.displayName, 'last login at:': date.toString(), 'profile picture': this.user.photoURL, 'email verified': this.user.emailVerified, 'phone number': this.user.phoneNumber,});
    },
    searchFile(){
      var file = document.getElementById("files").files[0];
      console.log('The selected file is: ',file);
    },
    listFiles(){
      var storageRef = firebase.storage().ref();
      var listRef = storageRef.child('/docs/'+this.user.uid);
      // Find all the prefixes and items.
      listRef.listAll().then(function(res) {
        res.prefixes.forEach(function(folderRef) {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          console.log('Docs are', listRef.storage)
        });
      }).catch(function(error) {
        // Uh-oh, an error occurred!
      });
    },
    async getAll() {
    var ref = firebase.database().ref('users/'+this.user.uid).orderByKey().equalTo(`${this.user.uid}`)
      ref.once('value', (snapshot) => {
        var childData2 = snapshot.child(this.user.uid).val();
        snapshot.forEach((childSnapshot) => {
          
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          
          this.applications = childData2;
          //var childDataIhm = childSnapshot.val();

          console.log('childKey', childKey);
          console.log('childData', childData);
          console.log('childData2', childData2);
          //console.log('applications nombre', this.applications['id_IHM_123_5586614218'])
          //console.log('applications', applications)
          //console.log('childData IHM', childDataIhm);

        });
      });
    },
    async getReportData() {
      fetch("reporte.json")
        .then(response => response.json())
        .then(data => (this.reportDataList = data));
    },
    goToGraphs(){
      this.$router.push('/graphs')
    }
  },
};
</script>
<style scoped>
  button{ 
    min-width: 150px;
    margin: 0.5rem 1rem; }
</style>